import React from "react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import ScrollButton from "../../components/ScrollBtn";
import MenuItem from "./MenuItem";
import ContactUsLanding from "../landing/ContactUsLanding";

const Menu = ({ allProducts,
  activeCategory,
}) => {

  const [itemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemOffset + 12);
  const [currentProducts, setcurrentProducts] = useState([...allProducts].reverse().slice(itemOffset, endOffset));

  useEffect(() => {
    document.title = `${activeCategory} | Hyllinge pizzeria`;
    setEndOffset(itemOffset + 100);
    setcurrentProducts([...allProducts].slice(itemOffset, endOffset));
  }, [allProducts, setEndOffset, endOffset, itemOffset, activeCategory]);

  let previousCategory = '';

  return (
    <motion.div
    initial={{ opacity: 0, translateX: -300  }}
    whileInView={{ opacity: 1 , translateX: 0  }}
      exit={{ opacity: 0,  translateX: -300  }}
      transition={{ duration: 1}}
    >
    <main className="menu-main">
      <div className="additional-info">
        <p>I alla pizzor ingår tomatsås och ost.</p>
        <p>EXTRA PÅLÄGG:</p>
        <p>10–30 kr. Mozzarella 20 kr extra.</p>
        <p>Alla pizzor kan fås glutenfria för 25 kr extra.</p>
        <p>Alla pizzor kan fås som amerikansk pizza för 20 kr extra.</p>
        <p>BARNPIZZA: 10 kr mindre än ordinarie pris.</p>
      </div>
      {currentProducts.length === 0 ? <article className="pizza-section"><p className="nothing-found">Hittade inga pizzor... </p></article> :
        <article className="pizza-section">
          {currentProducts.map((singleProduct, index) => (            
            previousCategory = index > 0 ? currentProducts[index - 1].category : '',
            <MenuItem
              key={singleProduct.index}
              singleProduct={singleProduct}
              previousCategory={previousCategory}
            />
          ))
          }
          < ScrollButton />
        </article>}
    </main>
    <ContactUsLanding />
    </motion.div>
  );
}


export default Menu;