import React from 'react'

const ContactUsLanding = () => {
  return (
    <section className="contact-us-landing flex-container flex-row txt-white" >
      {/* <motion.div
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 400, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        <img src={PizzaOne} alt="" className="parallax company-details-image" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 600, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        <img src={PizzaOne} alt="" className="parallax company-details-image-two" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, translateX: 1000 }}
        whileInView={{ opacity: 0.8, translateX: 800, }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        <img src={PizzaOne} alt="" className="parallax company-details-image-three" />      </motion.div> */}
      <section className='company-details'>
      <div>
          <h2>042 -22 53 31 </h2>
          <p></p>
        </div>
        <div>
          <h2>076-500 03 80</h2>
          <p></p>
        </div>        
        <div>
          <h2>Öppettider</h2>
          <p>Måndag-Torsdag: 11.00-21.00</p>
          <p>Fredag-Sönda:   11.00-21.30</p>
          <p></p>
        </div>
      </section>
    </section>
  );

}


export default ContactUsLanding;