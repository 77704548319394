import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";

import img375 from '../../assets/images/welcome/pizza-wine.jpg'
import { motion } from "framer-motion";

const WelcomeSection = () => {
  return (
    <article className="welcome-section" >
      <section className="section-2-info flex-container flex-column txt-center pop-font">
        <motion.div
          className='pizza-motion'
          initial={{ opacity: 0, translateX: -200 }}
          whileInView={{
            opacity: 1, translateX: -100,
            translateY: '-12%'
          }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          
        </motion.div>
        <motion.div
          className='pizza-motion'
          initial={{ opacity: 0, translateX: 200 }}
          whileInView={{
            opacity: 1, translateX: 100,
            translateY: '-12%'
          }}
          exit={{ opacity: 0 }}
          transition={{ duration: 4 }}
        >
          {/* <img src={PizzaOne} alt="" className=" pizza-one" /> */}
        </motion.div>
        <h2 className="txt-white">
          Välkommen till <span>Hyllinge pizzeria</span>
        </h2>
        <p>
        Välkommen till Hyllinge Pizzeria, där vi stolt serverar de bästa pizzorna i området! Vår kärlek för utsökta smaker och ingredienser av högsta kvalitet garanterar att du kommer att njuta av varje bit. Kom in och upplev vår varma atmosfär och låt dig övertygas om att Hyllinge Pizzeria är din nya favoritdestination för pizzautflykter!
        </p>
      </section>
      <LazyLoadImage
        className="section-two-img"
        src={img375}
        srcSet={`${img375} 1440w, ${img375} 700w, ${img375} 375w`}
        sizes="(min-width: 1440px) 1440px, (min-width: 700px) 700px, 375px"
        alt="Pizza Time restaurant interior with people at the tabel and the staff serving the customers"
      />
    </article>
  )
}

export default WelcomeSection;